<script setup>
import { computed, provide, ref, useSlots } from "vue";

const emit = defineEmits(["submit"]);
const watcher = ref(false);
const slots = useSlots();

function updateWatcher() {
  watcher.value = !watcher.value;
}

const validators = ref([]);

provide("riseForm", {
  watcher,
  addValidator: (validator) => {
    validators.value.push(validator);
  },
  removeValidator: (validator) => {
    const index = validators.value.indexOf(validator);
    if (index >= 0) {
      validators.value.splice(index, 1);
    }
  },
});

let allChildrenInputs = [];

function recursivelyFindKeyValue(key, keyValue, list) {
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (Array.isArray(item.children) && item.children?.length > 0) {
      recursivelyFindKeyValue(key, keyValue, item.children);
    } else if (Array.isArray(item[key]) && item[key].includes(keyValue)) {
      allChildrenInputs = [...new Set([...allChildrenInputs, ...list])];
    }
  }
}

const validated = computed(() => {
  recursivelyFindKeyValue("dynamicProps", "modelValue", slots.default());

  const hasInvalidInput = !allChildrenInputs.some((e) => {
    const methods = e.type.methods;
    if (!methods || !methods.allRules) return false;

    const value = e.props?.modelValue;
    const required =
      e.props?.required?.length >= 0 || e.props?.required === true;
    const rules = e.props?.rules || [];

    const allRules = methods.allRules(required, rules);
    return methods?.validate(value, allRules) === false;
  });

  allChildrenInputs = [];
  return hasInvalidInput;
});

async function onSubmit() {
  updateWatcher();
  let asyncValidated = true;
  for (const validator of validators.value) {
    if (!(await validator())) {
      asyncValidated = false;
    }
  }
  if (validated.value && asyncValidated) {
    emit("submit");
  }
}
</script>

<template>
  <form @submit.prevent="onSubmit">
    <slot />
  </form>
</template>
