export default {
  install: (app, { router }) => {
    router.beforeEach((to) => {
      const auth = () => true;

      if (to.meta.requiresAuth && !auth()) {
        return {
          path: "/",
          query: { redirect: to.fullPath },
        };
      }
    });

    app.use(router);
  },
};
