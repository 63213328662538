<script setup>
const email = ref(null);
const loading = ref(false);
const submitted = ref(false);

const verificationsStore = useVerificationsStore();

const features = [
  {
    icon: "wallet",
    title: "Connect external accounts",
    description:
      "Send and receive payments on Rise Pay with multiple accounts.",
  },
  {
    icon: "arrow-right-to-bracket",
    title: "Sign-in on multiple apps",
    description: "Connect to RiseID or Rise Pay with your verified wallet.",
  },
  {
    icon: "shield-halved",
    title: "Protect account",
    description: "Recovery account from apps with your wallet.",
  },
  {
    icon: "link",
    title: "Share attestation link",
    description: "Send a certification and proves you are the wallet owner.",
  },
];

async function submitEmail() {
  loading.value = true;
  try {
    await verificationsStore.sendEmail(email.value);
    submitted.value = true;
  } finally {
    loading.value = false;
  }
}
</script>

<template>
  <div>
    <h1 class="mb-6 text-4xl font-bold text-gray-800">
      Verify wallet ownership
    </h1>
    <template v-if="submitted">
      <RiseAlert variant="success" title="Email successfully sent!">
        You may close this window and please find the account confirmation email
        in your inbox. Click the link within to start wallet verification.
      </RiseAlert>
    </template>
    <template v-else>
      <RiseForm @submit="submitEmail">
        <RiseTextField
          v-model="email"
          label="Email address"
          placeholder="Email address"
          type="text"
          autocomplete="email"
          :rules="['required', 'email']"
          class="mb-7"
        />
        <RiseButton
          type="submit"
          text="Submit"
          size="l"
          fill
          class="mb-7"
          :loading="loading"
        />
      </RiseForm>
      <div class="relative mb-7">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center text-sm">
          <span class="bg-white px-5 text-base font-medium text-gray-800">
            You'll be able to
          </span>
        </div>
      </div>
      <div class="grid grid-cols-[repeat(auto-fill,_minmax(280px,_1fr))] gap-4">
        <div
          v-for="feature in features"
          :key="feature.icon"
          class="grid grid-cols-[50px,_1fr] gap-4 text-gray-800"
        >
          <div
            class="flex h-[50px] w-[50px] items-center justify-center rounded border border-gray-300"
          >
            <font-awesome-icon :icon="feature.icon" class="h-[20px] w-[20px]" />
          </div>
          <div>
            <div class="text-base font-medium">{{ feature.title }}</div>
            <div class="text-sm font-normal">
              {{ feature.description }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
