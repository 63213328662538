import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export default {
  install: (app, { router }) => {
    Sentry.init({
      app,
      dsn: "https://62fa162a45bc49d38aaf760ce7327524@o417911.ingest.sentry.io/4504828867903488",
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [
            "localhost",
            "verify-wallet.riseworks.io",
            "dev-verify-wallet.riseworks.io",
            "staging-verify-wallet.riseworks.io",
            /^\//,
          ],
        }),
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      ignoreErrors: [
        "Not connected to a wallet.",
        "Wallet not authorized",
        "TypeError: Failed to fetch",
        "TypeError: NetworkError when attempting to fetch resource.",
        "TypeError: Load failed",
        "TypeError: Importing a module script failed.",
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
    });
  },
};
