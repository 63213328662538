import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import {
  configureChains,
  createConfig,
  getNetwork,
  switchNetwork,
  watchAccount,
  watchNetwork,
} from "@wagmi/core";
import {
  arbitrum,
  arbitrumGoerli,
  avalanche,
  avalancheFuji,
  goerli,
  mainnet,
  optimism,
  optimismGoerli,
  polygon,
  polygonMumbai,
} from "@wagmi/core/chains";
import { Web3Modal } from "@web3modal/html";

export default {
  install: (app) => {
    const chains =
      import.meta.env.VITE_NODE_ENV === "production"
        ? [mainnet, polygon, avalanche, arbitrum, optimism]
        : [
            goerli,
            arbitrumGoerli,
            polygonMumbai,
            avalancheFuji,
            optimismGoerli,
          ];
    const projectId = import.meta.env.VITE_WALLETCONNECT_PROJECT_ID;

    const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
    const wagmiClient = createConfig({
      autoConnect: true,
      connectors: w3mConnectors({ projectId, version: 1, chains }),
      provider,
    });

    const ethereumClient = new EthereumClient(wagmiClient, chains);
    const web3modal = new Web3Modal(
      {
        projectId,
        explorerRecommendedWalletIds: [
          "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
          "3fecad5e2f0a30aba97edea69ebf015884a9b8a9aec93e66d4b4b695fee1f010",
          "f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d",
        ],
        explorerExcludedWalletIds: "ALL",
      },
      ethereumClient
    );

    watchAccount((account) => {
      try {
        const walletStore = useWalletStore();

        // Set states
        walletStore.address = account.address;
        walletStore.connector = account.connector?.name;
        walletStore.chain = getNetwork().chain;

        const network = getNetwork();
        if (
          account &&
          walletStore.desiredNetwork &&
          network.id !== walletStore.desiredNetwork
        ) {
          switchNetwork({ chainId: walletStore.desiredNetwork });
        }
      } catch (e) {
        console.log(e);
      }
    });

    watchNetwork((network) => {
      const walletStore = useWalletStore();
      walletStore.chain = network.chain;
    });

    app.provide("web3modal", web3modal);
  },
};
