import { ofetch } from "ofetch";

export default {
  install: (app) => {
    const apiFetch = ofetch.create({
      baseURL: import.meta.env.VITE_B2B_API_URL,
      onResponseError({ response }) {
        app.config.globalProperties.$toastError(
          response._data.message.toString()
        );
      },
    });

    app.config.globalProperties.$fetch = apiFetch;
  },
};
