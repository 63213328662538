import { defineStore } from "pinia";

import { SafeAppWeb3Modal } from "@gnosis.pm/safe-apps-web3modal";
// import Torus from "@toruslabs/torus-embed";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { ethers } from "ethers";
import { disconnect, getNetwork, switchNetwork } from "@wagmi/core";

export const useWalletStore = defineStore("wallet", {
  state: () => ({
    desiredNetwork: null,
    modal: null,
    ethereum: null,
    provider: null,
    address: null,
    isSafe: false,
    signer: null,
    chainId: null,
    stopChainWatcher: () => {},
    connector: null,
    chain: null,
  }),
  getters: {
    /*
    connector(state) {
      if (state.ethereum.isMetaMask === true) return "metamask";
      if (state.ethereum.isCoinbaseWallet === true) return "coinbase";
      if (state.ethereum.isTorus === true) return "torus";
      if (state.ethereum.isMewConnect === true) return "mew";
    },
    */
    connectorLogo(state) {
      const logoUrl = {
        MetaMask: new URL("@/web3/connectors/metamask.svg", import.meta.url),
        Coinbase: new URL("@/web3/connectors/coinbase.svg", import.meta.url),
        Torus: new URL("@/web3/connectors/torus.svg", import.meta.url),
        Mew: new URL("@/web3/connectors/mew.png", import.meta.url),
      };
      return logoUrl[state.connector].href;
    },
  },
  actions: {
    async connectWallet(network) {
      if (!network) return;

      try {
        if (!network.rpcUrl) network.rpcUrl = network.rpcUrls[0];
        if (!network.blockExplorerUrl)
          network.blockExplorerUrl = network.blockExplorerUrls[0];

        const chainId = network.chainNumber || network.chainId;
        const infuraId = "7282f14a28ef4c1f8be079b63154cf20";

        const _web3modalOptions = {
          // Disable displayed provider so user does not switch. Important because our system is implied on which provider to use
          disableInjectedProvider: false, // Just for now so we can test it with MetaMask
          cacheProvider: false,
          providerOptions: {
            injected: {
              options: {
                rpc: network.rpcUrl,
                chainId,
              },
            },
            coinbasewallet: {
              package: CoinbaseWalletSDK,
              options: {
                appName: "Rise Pay",
                infuraId,
              },
            },
            // torus: {
            //   // reference api: https://docs.tor.us/wallet/api-reference/class
            //   package: Torus,
            //   options: {
            //     network: {
            //       chainId: 43114,
            //     },
            //   },
            // },
          },
        };

        this.modal = new SafeAppWeb3Modal(_web3modalOptions);
        this.ethereum = await this.modal.requestProvider();
        this.chainId = this.ethereum.chainId;
        this.isSafe = await this.modal.isSafeApp();
        const addresses = await this.ethereum.request({
          method: "eth_requestAccounts",
        });
        this.provider = new ethers.providers.Web3Provider(this.ethereum);
        this.signer = this.provider.getSigner();
        this.address = ethers.utils.getAddress(addresses[0]);
        this.watchChainUpdate();
        this.watchAddressUpdate();
      } catch (e) {
        console.error(e);
        await this.disconnectWallet();
      }
    },
    async disconnectWallet() {
      disconnect();
      await this.modal?.clearCachedProvider();
      this.stopChainWatcher();
      this.ethereum?.removeListener("chainChanged", this.updateChain);
      this.modal = null;
      this.ethereum = null;
      this.provider = null;
      this.address = null;
      this.isSafe = false;
      this.signer = null;
      this.chainId = null;
      window.provider = null;
    },
    async changeNetwork(network) {
      const verificationsStore = useVerificationsStore();

      const chainNumber = verificationsStore.networks[network]?.chainNumber;

      try {
        await switchNetwork({ chainId: chainNumber });
        this.chain = getNetwork().chain;
      } catch (e) {
        this.$toastError("Wasn't possible to switch network.");
      }

      /*
      if (!this.ethereum) return;
      if (!verificationsStore.networks) return;

      const networkObject = toRaw(verificationsStore.networks[network]);

      const { chainId, blockExplorerUrls, chainName, nativeCurrency, rpcUrls } =
        networkObject;

      try {
        await this.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await this.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId,
                  blockExplorerUrls,
                  chainName,
                  nativeCurrency,
                  rpcUrls,
                },
              ],
            });
          } catch (addError) {
            this.$toastError("Wasn't possible to add a network.");
          }
        } else {
          this.$toastError("Wasn't possible to switch network.");
        }
      }
      */
    },
    watchChainUpdate() {
      this.stopChainWatcher = watchEffect(() => {
        this.ethereum?.on("chainChanged", this.updateChain);
      });
    },
    updateChain(chainId) {
      const chainIdHex = !isNaN(chainId)
        ? `0x${Number(chainId).toString(16)}`
        : chainId;

      this.chainId = chainIdHex;
    },
    watchAddressUpdate() {
      this.stopChainWatcher = watchEffect(() => {
        this.ethereum?.on("accountsChanged", this.updateAddress);
      });
    },
    updateAddress(accounts) {
      if (!accounts || accounts.length <= 0) return;
      this.address = accounts[0];
    },
  },
});
