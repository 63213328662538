import { defineStore } from "pinia";

export const useVerificationsStore = defineStore("verifications", {
  state: () => ({
    email: null,
    message: null,
    attestation: null,
    networks: null,
  }),
  actions: {
    async getChains() {
      return new Promise((resolve, reject) => {
        this.$fetch("verifications/chains/supported")
          .then((resp) => {
            this.networks = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async sendEmail(email) {
      await this.$recaptchaLoaded();
      const recaptchaToken = await this.$execute("verify_wallet_send_email");
      return new Promise((resolve, reject) => {
        this.$fetch("/verifications/email", {
          method: "POST",
          body: { email },
          query: { recaptchaToken },
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async checkEmail(token) {
      await this.$recaptchaLoaded();
      const recaptchaToken = await this.$execute("verify_wallet_check_email");
      return new Promise((resolve, reject) => {
        this.$fetch("/verifications/email/check", {
          method: "POST",
          body: { token },
          query: { recaptchaToken },
        })
          .then((resp) => {
            this.email = resp.data?.email || "useremail@gmail.com";
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getMessage({ token, address, network }) {
      await this.$recaptchaLoaded();
      const recaptchaToken = await this.$execute("verify_wallet_get_message");
      return new Promise((resolve, reject) => {
        this.$fetch("/verifications/wallet/check", {
          method: "PUT",
          body: { token, address, network },
          query: { recaptchaToken },
        })
          .then((resp) => {
            this.message = resp.data?.message;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async sendSignature({ token, signature, network }) {
      await this.$recaptchaLoaded();
      const recaptchaToken = await this.$execute(
        "verify_wallet_send_signature"
      );
      return new Promise((resolve, reject) => {
        this.$fetch("/verifications/wallet/check", {
          method: "POST",
          body: { token, signature, network },
          query: { recaptchaToken },
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async checkAttestation(token) {
      return new Promise((resolve, reject) => {
        this.$fetch("/verifications/certificate", {
          query: { token },
        })
          .then((resp) => {
            this.attestation = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
