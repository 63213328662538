<script setup></script>

<template>
  <div
    class="min-w-screen min-h-screen bg-white font-serif md:grid md:grid-cols-[fit-content(480px)_minmax(450px,_1fr)]"
  >
    <div
      class="relative overflow-hidden bg-dashboard-bg px-4 py-6 md:h-full md:w-full md:px-20 md:py-10"
    >
      <div class="pointer-events-none absolute left-0 top-0 h-full w-full">
        <lottie-animation
          class="absolute -left-[140px] -top-[227px] !h-auto !w-[1400px] rotate-[160deg] md:-top-[40px]"
          animation-link="/lottie/feathers.json"
        />
      </div>
      <div class="relative">
        <img
          src="@/logos/rise-logo-dark.svg"
          class="mx-auto mb-6 block h-[30px] w-auto md:mx-0 md:mb-12 md:h-[43px]"
        />
        <div
          class="mb-2 bg-gradient-to-r from-[#3CA4E0] to-[#4E62D8] bg-clip-text text-[30px] font-bold text-transparent md:text-[48px]"
        >
          Rise Pay
        </div>
        <div class="text-xl font-light text-white opacity-70">
          The most flexible way to get paid in fiat and crypto.
        </div>
      </div>
    </div>
    <RouterView
      class="mx-auto h-full w-full max-w-[664px] px-4 pb-7 pt-7 md:px-8 md:pb-8 md:pt-32"
    />
  </div>
</template>
